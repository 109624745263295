jQuery(function ($) {
  // This move only TopPage

  // tracking nav(right)
  var trackingNav = $('#tracking-nav');
  trackingNav.hide($);
  jQuery(window).scroll(function () {
    if ($(this).scrollTop() > 1400) {
      trackingNav.fadeIn('slow');
    } else {
      trackingNav.fadeOut();
    }
  });

  // ???
  var trackingNav2 = $('#tracking-nav2');
  trackingNav2.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      trackingNav2.fadeIn('slow');
    } else {
      trackingNav2.fadeOut();
    }
  });

  // tracking nav(left)
  var shopBanner = $('.uedashop-nav');
  shopBanner.hide();
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 1400) {
      shopBanner.fadeIn('slow');
    } else {
      shopBanner.fadeOut();
    }
  });

  // tracking nav(left)
  var pinchBanner = $('.uedashop-nav3');
  pinchBanner.hide();
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 1400) {
      pinchBanner.fadeIn('slow');
    } else {
      pinchBanner.fadeOut();
    }
  });
});